import React from 'react';
import styled from 'styled-components';
import { theme } from 'styles/styled';

import ResponsiveButtonBase, { Skew } from './styled';

interface GhostButtonProps {
  skew: Skew;
  children: React.ReactChild;
  fixedHeader?: boolean;
  isRtl: boolean;
}

const GhostButtonWrapper = styled(ResponsiveButtonBase)<GhostButtonProps>`
  background: transparent;
  direction: ${(props) => (props.isRtl ? 'rtl' : 'ltr')};

  color: ${theme.palette.primary};

  padding-right: 11px;
  border: ${theme.ui.borderMedium(theme.palette.primary)};
  border-right: 0;

  ${({ skew }) =>
    skew === Skew['bottom-right'] ? `border-bottom: 0;` : 'border-top: 0;'}

  text-transform: uppercase;
  outline: none;

  > * {
    white-space: nowrap;
    padding: ${theme.dimensions.spacingBase16}px 30px
      ${theme.dimensions.spacingBase16}px 40px;

    ${({ skew }) =>
      skew === Skew['bottom-right']
        ? `border-bottom: ${theme.ui.borderMedium(theme.palette.primary)};`
        : `border-top: ${theme.ui.borderMedium(theme.palette.primary)};`}
  }

  ::after {
    pointer-events: none;
    content: '';
    position: absolute;
    top: ${({ skew }) => (skew === Skew['bottom-right'] ? '-2px' : '0')};
    right: -45px;
    width: 52px;
    height: calc(100% + 2px);

    background-color: transparent;
    transform: ${({ skew }) =>
      `skewX(${skew === Skew['top-right'] ? '13deg' : '-13deg'})`};
    border-left: 2px solid ${theme.palette.primary};
  }
`;

const GhostButtonExport = ({
  children,
  ...props
}: GhostButtonProps & React.PropsWithRef<JSX.IntrinsicElements['button']>) => (
  <GhostButtonWrapper {...props}>
    <div>{children}</div>
  </GhostButtonWrapper>
);

export { Skew } from './styled';
export default GhostButtonExport;
