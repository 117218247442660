import styled from 'styled-components';
import { theme } from 'styles/styled';

export enum Skew {
  'top-right',
  'bottom-right',
  'top-left',
  'bottom-left',
  'none',
}

const ResponsiveButtonBase = styled.button`
  position: relative;

  font-size: ${theme.fontSize.label};
  font-weight: ${theme.fontWeight.medium};

  :hover {
    opacity: 0.9;
    cursor: pointer;
  }

  :active,
  :focus {
    opacity: 0.75;
  }

  @media ${theme.devices.medium} {
    font-size: ${theme.fontSize.base};
  }
`;

export default ResponsiveButtonBase;
