import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { useIsRtl } from 'hooks/useCurrentLocale';

import SEO, { SEOMeta } from 'components/SEO/SEO';
import MinimalLayout from 'components/MinimalLayout';
import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';
import GhostButton, { Skew } from 'components/Button/GhostButton';
import Footer from 'components/Footer';
import { theme } from 'styles/styled';
import {
  translationsFactory,
  TranslationResult,
  TranslationsContext,
  useTranslations,
} from 'settings/i18n';
import useLinkCreator from 'hooks/useLinkCreator';
import menuLinks from 'settings/menuLinks';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${theme.dimensions.spacingBase16 * 3}px;
`;

const Heading404 = styled(Heading)`
  margin-top: ${theme.dimensions.spacingBase16 * 2}px;
  font-size: ${theme.fontSize.pageTitle};

  @media ${theme.devices.medium} {
    margin-top: auto;
    font-size: ${theme.fontSize.headingLarge};
  }
`;

const PageNotFoundHeading = styled(Heading)`
  margin-top: ${theme.dimensions.spacingBase16 * 0.75}px;
  font-size: ${theme.fontSize.pageTitleMobile};

  @media ${theme.devices.medium} {
    margin-top: ${theme.dimensions.spacingBase16 * 0.75}px;
    font-size: ${theme.fontSize.headingBig};
  }
`;

const Text404 = styled(Text)`
  text-align: center;
  margin-top: ${theme.dimensions.spacingBase16 * 3}px;
  padding: 0 ${theme.dimensions.spacingBase16 * 1.5}px;
`;

const GoBackButton = styled(GhostButton)`
  margin-top: ${theme.dimensions.spacingBase16 * 3.5}px;
  margin-bottom: 100px;
  font-size: ${theme.fontSize.label};
`;

const NotFoundPageProviders = ({ data }: { data: TranslationResult }) => {
  const t = translationsFactory({
    wpPage: {
      id: 'translations',
      translation: {
        ...data?.homepage?.translation,
      },
    },
  });

  return (
    <TranslationsContext.Provider value={t}>
      <NotFoundPage metadata={data.metadata} />
    </TranslationsContext.Provider>
  );
};

const NotFoundPage = ({ metadata }: { metadata: SEOMeta }) => {
  const t = useTranslations();
  const isRtl = useIsRtl();
  const createLink = useLinkCreator();

  return (
    <MinimalLayout>
      <SEO url="/404" metadata={metadata || undefined} />
      <Wrapper>
        <Heading404
          isRtl={isRtl}
          size="headingLarge"
          color="primary"
          weight="medium"
          letterSpacing="normal">
          404
        </Heading404>

        <PageNotFoundHeading
          isRtl={isRtl}
          size="headingBig"
          color="primary"
          weight="medium"
          letterSpacing="normal">
          {`${t('page')} `}
          <PageNotFoundHeading
            isRtl={isRtl}
            size="headingBig"
            color="dark"
            weight="light"
            letterSpacing="normal">
            {t('notFound')}
          </PageNotFoundHeading>
        </PageNotFoundHeading>

        <Text404 isRtl={isRtl}>
          {t('notFoundApologies')}
          <br />
          {t('tryOtherSiteOrGoBackToPreviousPage')}
        </Text404>

        <Link to={createLink(menuLinks.home)}>
          <GoBackButton isRtl={isRtl} skew={Skew['bottom-right']}>
            {t('goBack')}
          </GoBackButton>
        </Link>
      </Wrapper>
      <Footer />
    </MinimalLayout>
  );
};

export default NotFoundPageProviders;

export const query = graphql`
  query($locale: String!) {
    metadata: wpPage(
      slug: { regex: "/\\b(\\w*404\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...MetaData
    }

    homepage: wpPage(
      slug: { regex: "/\\b(\\w*home-page\\w*)+(-\\d+)?\\b/" }
      language: { locale: { eq: $locale } }
    ) {
      ...FooterTranslation
      ...HeaderTranslation
      ...NotFoundTranslation
      ...AboutTranslation
    }
  }
`;
