import React from 'react';

const CustomFacebookIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}>
    <defs>
      <filter id="s21i2zsgta">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
      <path id="159qbnz1ab" d="M0.001 0H30.001V30H0.001z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g
          filter="url(#s21i2zsgta)"
          transform="translate(-943 -1010) translate(308 1010)">
          <g transform="translate(635)">
            <mask id="zd57163kqc" fill="#fff">
              <use xlinkHref="#159qbnz1ab" />
            </mask>
            <path
              fill="#000"
              fillRule="nonzero"
              d="M15 0C6.716 0 0 6.757 0 15.09 0 22.622 5.485 28.865 12.657 30V19.454h-3.81V15.09h3.81v-3.325c0-3.785 2.24-5.873 5.664-5.873 1.641 0 3.36.295 3.36.295v3.71h-1.896c-1.86 0-2.442 1.166-2.442 2.36v2.83H21.5l-.664 4.363h-3.493v10.546C24.515 28.867 30 22.623 30 15.091 30 6.757 23.284 0 15 0z"
              mask="url(#zd57163kqc)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CustomFacebookIcon;
