import React from 'react';

import { Link } from 'gatsby';
import SocialMediaIconLink from 'components/Footer/SocialMediaIconLink';
import Triangle, { CornerTypes } from 'components/Triangle/Triangle';
import { theme } from 'styles/styled';
import socialMediaLinks from 'settings/social-media-links';
import InstagramIcon from 'images/Icons/CustomInstagramIcon';
import TwitterIcon from 'images/Icons/CustomTwitterIcon';
import TelegramIcon from 'images/Icons/CustomTelegramIcon';
import YoutubeIcon from 'images/Icons/CustomYoutubeIcon';
import FacebookIcon from 'images/Icons/CustomFacebookIcon';
import BuildDate from 'components/BuildDate/BuildDate';

import { useTranslations } from 'settings/i18n';
import menuLinks from 'settings/menuLinks';
import useLinkCreator from 'hooks/useLinkCreator';
import { useIsRtl } from 'hooks/useCurrentLocale';

import {
  Container,
  Footer,
  FooterMenu,
  FooterLinks,
  IconsWrapper,
  MenuLinksSeparator,
  PolicyLinksWrapper,
  Copyright,
  MenuLink,
  SignInMenuLink,
  PolicyLink,
  CustomSocialMediaIconLink,
} from './styled';

const FooterComponent = () => {
  const t = useTranslations();
  const createLink = useLinkCreator();
  const isRtl = useIsRtl();

  return (
    <Footer>
      <Triangle
        width="100%"
        height="106px"
        zIndex={101}
        cornerType={CornerTypes['bottom-right']}
        color={theme.palette.primary}
        position={{ right: 0, top: '-105px' }}
      />
      <Triangle
        width="100%"
        height="36px"
        zIndex={101}
        showOnMobile
        cornerType={CornerTypes['bottom-right']}
        color={theme.palette.primary}
        position={{ right: 0, top: '-35px' }}
      />
      <Container>
        <FooterMenu>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as={Link}
            to={createLink(menuLinks.aboutUs)}>
            {t('aboutUs')}
          </MenuLink>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as={Link}
            to={createLink(menuLinks.howTo)}>
            {t('howTo')}
          </MenuLink>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as={Link}
            to={createLink(menuLinks.events)}>
            {t('events')}
          </MenuLink>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as={Link}
            to={createLink(menuLinks.education)}>
            {t('education')}
          </MenuLink>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as={Link}
            to={createLink(menuLinks.blog)}>
            {t('blog')}
          </MenuLink>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as={Link}
            to={createLink(t('webinarsLink'))}>
            {t('webinarsTitle')}
          </MenuLink>

          <MenuLinksSeparator />

          <SignInMenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href={createLink(menuLinks.signIn)}>
            {t('signIn')}
          </SignInMenuLink>
          <MenuLink
            isRtl={isRtl}
            color="light"
            weight="medium"
            as="a"
            target="_blank"
            rel="noopener noreferrer"
            href={createLink(menuLinks.signUp)}>
            {t('signUp')}
          </MenuLink>
        </FooterMenu>

        <FooterLinks>
          <IconsWrapper>
            <SocialMediaIconLink
              href={socialMediaLinks.instagram}
              aria-label="Instagram link">
              <InstagramIcon />
            </SocialMediaIconLink>

            <CustomSocialMediaIconLink
              href={socialMediaLinks.twitter}
              aria-label="Twitter link">
              <TwitterIcon />
            </CustomSocialMediaIconLink>
            <CustomSocialMediaIconLink
              href={t('telegramLink')}
              aria-label="Telegram link">
              <TelegramIcon />
            </CustomSocialMediaIconLink>

            <CustomSocialMediaIconLink
              href={socialMediaLinks.youtube}
              aria-label="YouTube link">
              <YoutubeIcon />
            </CustomSocialMediaIconLink>

            <CustomSocialMediaIconLink
              href={socialMediaLinks.facebook}
              aria-label="Facebook link">
              <FacebookIcon />
            </CustomSocialMediaIconLink>
          </IconsWrapper>

          <div>
            <PolicyLinksWrapper>
              <PolicyLink
                isRtl={isRtl}
                color="light"
                weight="medium"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                href={createLink(menuLinks.privacyPolicy)}>
                {t('privacyPolicy')}
              </PolicyLink>
              <Copyright isRtl={isRtl} color="light" weight="medium">
                © {t('allRightsReserved')}
              </Copyright>
            </PolicyLinksWrapper>
          </div>
        </FooterLinks>
      </Container>
      <BuildDate />
    </Footer>
  );
};

export default FooterComponent;
